<template>
  <v-container>
    <v-card style="text-align: center">
      <v-card-title></v-card-title>
      <v-card-text>
        <v-layout row wrap>
          <v-flex xs12>
            {{ message }}
            <div v-for="(e, idx) in errors" :key="idx">
              <div>
                {{ e }}
                <br />
                <br />
              </div>
            </div>
          </v-flex>
        </v-layout>
        <v-progress-linear
          color="primary"
          indeterminate
          v-if="isRemoving"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import { authComputed, authMethods } from '@state/helpers'
export default {
  page: {
    title: 'Confirm Email',
  },
  data() {
    return {
      message: '',
      isRemoving: true,
      errors: [],
    }
  },
  created() {
    let email = this.$route.query.email
    this.message = `Confirming account for ${email}`
    this.addUser(email)
  },
  computed: {
    ...authComputed,
  },
  methods: {
    ...authMethods,
    async addUser(email) {
      this.errors = []
      let token = this.$route.query.token
      const self = this
      if (email && token) {
        try {
          if (!this.identityUrl) {
            await this.getIdentityUrl()
          }
          await this.$axios.get(
            `${
              this.identityUrl
            }/api/Auth/ConfirmEmail?email=${encodeURIComponent(
              email
            )}&token=${token}`
          )
          this.isRemoving = false
          this.message = `Account for ${email} has been confirmed`
        } catch (err) {
          // this.handleError(err)
          this.isRemoving = false
          this.message = `There was an error confirming account for ${email} -`
          let e = err.response.data
          if (JSON.stringify(e).substring(0, 1) != '{') {
            self.errors.push(e)
          } else {
            Object.keys(e).forEach((val) => {
              self.errors.push(e[val][0])
            })
          }
        }
      } else {
        this.isRemoving = false
        this.message = `Unable to confirm account`
      }
    },
  },
}
</script>

<style></style>
